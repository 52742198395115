import React, { ReactNode, useEffect, useState } from 'react';

import MaintenanceWindowContext from '@totem/components/maintenanceWindows/maintenanceWindowContext';
import MaintenanceWindowEditDialog from '@totem/components/maintenanceWindows/maintenanceWindowEditDialog';
import { MaintenanceWindow } from '@totem/components/maintenanceWindows/types';
import { Params } from '@totem/types/common';
import { getToken } from '@totem/utilities/accountUtilities';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  children?: ReactNode;
};

const MaintenanceWindowsContainer = ({ children }: Props) => {
  const [data, setData] = useState<MaintenanceWindow[]>([]);
  const [loading, setLoading] = useState(true);
  const [actionItem, setActionItem] = useState<MaintenanceWindow>(null);
  const [action, setAction] = useState<string>('');
  const [refreshData, setRefreshData] = useState<boolean>(true);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setLoading(true);

      const params: Params = {
        paging: {
          page: 1,
          pageSize: 10000,
        },
        sort: {
          field: 'details.scheduledStartTime',
          direction: -1,
        },
        filters: [],
      };

      console.log('Token: ' + getToken());

      fetch(`${DEVICES_ENDPOINT}/maintenanceWindows`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then((res) => res.json())
        .then((result: MaintenanceWindow[]) => {
          setData(result);
        })
        .then(() => {
          setLoading(false);
        });
    }
  }, [refreshData]);

  const handleAction = (
    newAction: string,
    newActionItem: MaintenanceWindow,
  ) => {
    switch (newAction) {
      case 'refresh':
        setRefreshData(true);
        break;
      case 'clear':
        setActionItem(null);
        setAction('');
        break;
      default:
        setActionItem(newActionItem);
        setAction(newAction);
        break;
    }
  };

  return (
    <MaintenanceWindowContext.Provider
      value={{
        loading,
        data,
        action,
        actionItem,
        onAction: handleAction,
      }}
    >
      {children}
      {action === 'edit' && (
        <MaintenanceWindowEditDialog
          open={action === 'edit'}
          onClose={() => handleAction('close', null)}
          maintenanceWindow={actionItem}
        />
      )}
    </MaintenanceWindowContext.Provider>
  );
};

export default MaintenanceWindowsContainer;
