import React, { ReactNode, useEffect, useState } from 'react';
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import TicketAgingContext from '@totem/components/ticketing/agingReport/TicketAgingContext';
import { TicketingAgingConnectionInput } from '@totem/components/ticketing/agingReport/types';
import {
  TicketingFilterOptions,
  TicketingFilters,
} from '@totem/types/ticketing';
import { getToken } from '@totem/utilities/accountUtilities';
import { TICKETS_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

type Props = {
  refresh?: boolean;
  onDataRefreshRequested?: () => void;
  children?: ReactNode;
  reportType: string;
  ticketChartEndPoint?: string;
  ticketReportEndPoint?: string;
  defaultFilters?: TicketingFilters;
  staticFilters?: TicketingFilters;
};

const TicketAgingContainer = ({
  refresh,
  onDataRefreshRequested,
  children,
  reportType,
  ticketChartEndPoint,
  ticketReportEndPoint,
  defaultFilters,
  staticFilters,
}: Props) => {
  const [input, updateInput] = useQueryParams({
    from: withDefault(StringParam, ''),
    pageSize: withDefault(NumberParam, 10),
    page: withDefault(NumberParam, 1),
    sortField: withDefault(StringParam, 'createdAt'),
    sortDirection: withDefault(StringParam, '1'),
    ids: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.ids !== 'undefined' &&
      defaultFilters.ids !== null
        ? defaultFilters.ids
        : [],
    ),
    buildingId: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.buildingId !== 'undefined' &&
        defaultFilters.buildingId !== null
        ? defaultFilters.buildingId
        : [],
    ),
    regionId: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.regionId !== 'undefined' &&
        defaultFilters.regionId !== null
        ? defaultFilters.regionId
        : [],
    ),
    controlSystemId: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.controlSystemId !== 'undefined' &&
        defaultFilters.controlSystemId !== null
        ? defaultFilters.controlSystemId
        : [],
    ),
    deviceId: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.deviceId !== 'undefined' &&
        defaultFilters.deviceId !== null
        ? defaultFilters.deviceId
        : [],
    ),
    type: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.type !== 'undefined' &&
        defaultFilters.type !== null
        ? defaultFilters.type
        : [],
    ),
    status: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.status !== 'undefined' &&
        defaultFilters.status !== null
        ? defaultFilters.status
        : [],
    ),
    reference: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.reference !== 'undefined' &&
        defaultFilters.reference !== null
        ? defaultFilters.reference
        : [],
    ),
    category: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.category !== 'undefined' &&
        defaultFilters.category !== null
        ? defaultFilters.category
        : [],
    ),
    priority: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.priority !== 'undefined' &&
        defaultFilters.priority !== null
        ? defaultFilters.priority
        : [],
    ),
  });

  //const [refreshData, setRefreshData] = useState<boolean>(true);
  const [drillDownValue, setDrillDownValue] = useState<string>(null);
  //const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFilterOptionsLoaded, setIsFilterOptionsLoaded] =
    useState<boolean>(false);
  const [filterOptions, setFilterOptions] = useState<TicketingFilterOptions>({
    type: [],
    priority: [],
    status: [],
    subStatus: [],
    categories: [],
    building: [],
    organization: [],
    device: [],
  });
  const setInput = (updated: Partial<TicketingAgingConnectionInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }), 'replace');
    //setRefreshData(true);
  };

  useEffect(() => {
    if (
      refresh &&
      typeof onDataRefreshRequested !== 'undefined' &&
      onDataRefreshRequested !== null
    ) {
      //setRefreshData(true);
      onDataRefreshRequested();
    }
  }, [refresh, onDataRefreshRequested]);

  // useEffect(() => {
  //   setRefreshData(true);
  // }, [ticketChartEndPoint, ticketReportEndPoint, staticFilters]);

  useEffect(() => {
    if (!isFilterOptionsLoaded) {
      fetch(`${TICKETS_ENDPOINT}/filterOptions`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: TicketingFilterOptions) => {
          setFilterOptions(result);
        })
        .then(() => {
          setIsFilterOptionsLoaded(true);
        });
    }
  }, [isFilterOptionsLoaded]);

  return (
    <TicketAgingContext.Provider
      value={{
        input,
        setInput,
        setDrillDownValue,
        loading: false,
        filterOptions,
        reportType,
        chartEndPoint: ticketChartEndPoint,
        drillDownEndPoint: ticketReportEndPoint,
        staticFilters,
        drillDownValue,
        filterOptionsLoaded: isFilterOptionsLoaded,
      }}
    >
      <div>{children}</div>
    </TicketAgingContext.Provider>
  );
};

export default TicketAgingContainer;
