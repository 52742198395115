import React, { useCallback, useContext, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Modal,
  notification,
  Row,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import colors from '@totem/styles/colors';
import DeviceContext from '@totem/components/devices/devicesContainer/deviceContainerContext';
import { MaintenanceWindow } from '@totem/components/maintenanceWindows/types';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import IBDIcon from '@totem/styles/Icon';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull, isNull } from '@totem/utilities/common';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';
import { emptyId } from '@totem/utilities/userUtilities';
import { differenceInHours } from '@totem/components/maintenanceWindows/utilities';
import { isIBUser } from '@totem/utilities/security';

const styles = {
  dialog: {
    maxHeight: '80%',
    maxWidth: '850px',
  },
  label: {
    fontSize: '12px',
    fontWeight: 600,
  },
};

type Props = {
  open: boolean;
  mode: string;
  onClose: () => void;
};

const MaintenanceWindowDialog = ({ open, onClose }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const { selectedDevices, onAction, clearSelectedDevices, deselectDevice } =
    useContext(DeviceContext);
  const [isSending, setIsSending] = useState(false);
  const [startTime, setStartTime] = useState<Dayjs>(
    dayjs().set('hour', 0).set('minute', 0).set('second', 0),
  );
  const [endTime, setEndTime] = useState<Dayjs>(
    dayjs().set('hour', 0).set('minute', 0).set('second', 0),
  );
  const [siteContactName, setSiteContactName] = useState<string>('');
  const [siteContactPhone, setSiteContactPhone] = useState<string>('');
  const [siteContactEmail, setSiteContactEmail] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [requiresBypass, setRequiresBypass] = useState<boolean>(false);
  const [endTimeSelected, setEndTimeSelected] = useState<boolean>(false);

  const isIB = isIBUser(userProfile);
  const currentTime = dayjs();

  const sendCreateMaintenanceWindow = useCallback(
    async (payload: MaintenanceWindow) => {
      if (!isSending) {
        setIsSending(true);

        fetch(`${DEVICES_ENDPOINT}/maintenanceWindows`, {
          method: 'PUT',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
          body: JSON.stringify(payload),
        }).then((res) => {
          setIsSending(false);
          const success = CheckResponseShowError(res);
          if (success) {
            clearSelectedDevices();
            onAction('refresh', null);
            onClose();
          }
        });
      }
    },
    [],
  );

  const isValid = () => {
    let hasError = false;
    if (!startTime.isAfter(currentTime)) {
      hasError = true;

      notification.error({
        message: 'Invalid Date/Time Entry!',
        description:
          'Start time is in the past,  must be some point in the future!',
        duration: 3,
      });
    }

    if (!endTime.isAfter(startTime)) {
      hasError = true;

      notification.error({
        message: 'Invalid Date/Time Entry!',
        description:
          'End time must be after start time!',
        duration: 3,
      });
    }

    if (reason === '') {
      hasError = true;

      notification.error({
        message: 'Reason Required!',
        description:
          'The reason for the maintenance window is a required field!',
        duration: 3,
      });
    }

    if (!isIB && requiresBypass && (siteContactName === "" || siteContactPhone === "")) {
      hasError = true;

      notification.error({
        message: 'Site Contact Required!',
        description:
          'Site contact name & phone number is required when firewall bypass is requested!',
        duration: 3,
      });
    }

    if (isNull(selectedDevices) || selectedDevices.length === 0) {
      hasError = true;

      notification.error({
        message: 'No Devices Selected!',
        description: 'No devices selected for the maintenance window!',
        duration: 3,
      });
    }

    return !hasError;
  };

  const handleSubmit = () => {
    if (isValid()) {
      const payload: MaintenanceWindow = {
        id: emptyId,
        organizationId: userProfile.organization.id,
        details: {
          id: emptyId,
          scheduledStartTime: startTime.toISOString(),
          scheduledEndTime: endTime.toISOString(),
          actualEndTime: endTime.toISOString(),
          status: 'OPEN',
          siteContactName,
          siteContactPhone,
          siteContactEmail,
          reason,
          description,
          requiresBypass,
          bypassApproved: false,
        },
        approvals: [],
        overlayNetworkBypasses: [],
        keys: [],
        devices: selectedDevices.map((device) => {
          return {
            device: {
              id: device.device.id,
              name: device.device.displayName,
            },
            region: {
              id: device.region.id,
              name: device.region.name,
            },
            building: {
              id: device.building.id,
              name: device.building.name,
            },
            controlSystem: {
              id: device.controlSystem.id,
              name: device.controlSystem.name,
            },
          };
        }),
        blockedEvents: [],
        createdBy: userProfile.email,
        createdAt: currentTime.toISOString(),
        updatedBy: userProfile.email,
        updatedAt: currentTime.toISOString(),
      };

      sendCreateMaintenanceWindow(payload);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={() => onClose()}
      title={<ModalTitle>Maintenance Window</ModalTitle>}
      confirmLoading={false}
      style={styles.dialog}
      footer={
        <ModalFooter>
          <Button onClick={() => onClose()}>Close</Button>
          <Button onClick={handleSubmit} type="primary" disabled={isSending}>
            Submit
          </Button>
        </ModalFooter>
      }
    >
      <div style={styles.label}>
        Start Time: <span style={{ color: colors.utility.error }}>*</span>
      </div>
      <div>
        <DatePicker
          showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
          value={startTime}
          onChange={(time) => {
            setStartTime(time);
            if (!endTimeSelected) {
              setEndTime(time);
            }
          }}
          format={'L LT'}
          style={{ width: '50%' }}
        />
      </div>
      <br />

      <div style={styles.label}>
        End Time: <span style={{ color: colors.utility.error }}>*</span>
      </div>
      <div>
        <DatePicker
          showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
          value={endTime}
          onChange={(time) => {
            setEndTime(time);
            setEndTimeSelected(true);
          }}
          format={'L LT'}
          style={{ width: '50%' }}
        />
      </div>
      <br />

      <div style={styles.label}>
        Reason: <span style={{ color: colors.utility.error }}>*</span>
      </div>
      <div>
        <Input value={reason} onChange={(val) => setReason(val.target.value)} />
      </div>
      <br />

      <div style={styles.label}>Description:</div>
      <div>
        <Input
          value={description}
          onChange={(val) => setDescription(val.target.value)}
        />
      </div>
      <br />

      <div style={styles.label}>
        Site Contact:{' '}
        {!isIB && requiresBypass && (
          <span style={{ color: colors.utility.error }}>*</span>
        )}
      </div>
      <div style={styles.label}>Name:</div>
      <div>
        <Input
          value={siteContactName}
          onChange={(val) => setSiteContactName(val.target.value)}
        />
      </div>
      <div style={styles.label}>Phone:</div>
      <div>
        <Input
          value={siteContactPhone}
          onChange={(val) => setSiteContactPhone(val.target.value)}
        />
      </div>
      <div style={styles.label}>Email:</div>
      <div>
        <Input
          value={siteContactEmail}
          onChange={(val) => setSiteContactEmail(val.target.value)}
        />
      </div>
      <br />

      <Checkbox
        checked={requiresBypass}
        onChange={(evt) => setRequiresBypass(evt.target.checked)}
      >
        Maintenance Requires Firewall Bypass
      </Checkbox>
      {differenceInHours(startTime, endTime) > 4.0 && requiresBypass && (
        <div style={{ color: colors.utility.error }}>
          Service window requests exceeding 4 hours require additional review
          before approval. To prevent delays to site work, please submit
          requests at least 24 hours in advance.
        </div>
      )}
      <br />
      <br />

      <div style={styles.label}>Devices:</div>
      {isNotNull(selectedDevices) &&
        selectedDevices.length > 0 &&
        selectedDevices.map((device) => (
          <Row key={device.device.id}>
            <Col span={8}>{device.device.displayName}</Col>
            <Col span={8}>{device.controlSystem.name}</Col>
            <Col span={7}>{device.building.name}</Col>
            <Col span={1}>
              <IBDIcon
                name={'Delete'}
                title={'Remove'}
                onClick={() => deselectDevice(device)}
              />
            </Col>
          </Row>
        ))}
    </Modal>
  );
};

export default MaintenanceWindowDialog;
